import React from 'react'
import Footer from './Footer'
import About from './About'
import Contact from './Contact'
import Hero from './Hero'
import Navbar from './Navbar'

const Body = () => {

  return (
    <div>
      <Navbar></Navbar>
      <main>
        <Hero></Hero>
        <div className='w-full px-8 md:px-0 md:max-w-[80vw] mx-auto'>
        <About />
        <Contact />
        </div>
      </main>
      <Footer />
      
    </div>
  )
}

export default Body
