import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SiGoogleauthenticator } from "react-icons/si";
import { productList } from "../utils/constants"; // Adjust the import path as needed

function Hero() {
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  // Filter products based on search input
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length > 0) {
      const filteredProducts = productList.filter((product) =>
        product.ProductName.toLowerCase().includes(query.toLowerCase())
      );
      setSuggestions(filteredProducts);
    } else {
      setSuggestions([]);
    }
  };

  // Redirect to product page on click
  const handleSelectProduct = (productName) => {
    navigate(`/products/${productName}`);
  };

  return (
    <div className="h-screen w-full img">
      <div className="flex flex-col gap-4 justify-center h-[100%] items-center w-full px-8 md:px-0 md:max-w-[80vw] mx-auto">
      <div className="absolute inset-0 bg-black opacity-30"></div>
        <h1 className="scroll-m-20 pb-2 text-6xl md:text-7xl font-extrabold tracking-tight text-white text-center relative z-10">
          Committed to healthier tomorrow
        </h1>

        <div className="w-[80vw] lg:w-80 relative">
          <label htmlFor="Search" className="sr-only">
            Search
          </label>

          <input
            type="text"
            id="Search"
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Search for..."
            className="w-full rounded-md border-gray-200 p-2.5 shadow-sm sm:text-sm"
          />

          {/* Dropdown suggestions */}
          {suggestions.length > 0 && (
            <ul className="absolute z-10 w-full bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto mt-1">
              {suggestions.map((product) => (
                <li
                  key={product.ProductName}
                  className="cursor-pointer p-2 hover:bg-gray-200"
                  onClick={() => handleSelectProduct(product.ProductName)}
                >
                  {product.ProductName}
                </li>
              ))}
            </ul>
          )}

          <span className="absolute inset-y-0 end-0 grid w-10 place-content-center">
            <button type="button" className="text-gray-600 hover:text-gray-700">
              <span className="sr-only">Search</span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </button>
          </span>
        </div>
        <div className="absolute top-[86vh] left-[5vw] font-bold flex justify-center items-center gap-4 text-titleText bg-primaryAccent p-4 rounded-md">
          <SiGoogleauthenticator className="text-4xl"></SiGoogleauthenticator>
          <span className="w-32">100% Genuine medicine</span>
        </div>
      </div>
    </div>
  );
}

export default Hero;
