import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const SERVICE_ID = "";
    const TEMPLATE_ID = "";
    const PUBLIC_KEY = "";
    
    try {
      await emailjs.send(
        SERVICE_ID,
        TEMPLATE_ID,
        formData,
        PUBLIC_KEY
      );

      console.log('Email sent successfully!🚀');
    } catch (error) {
      console.error('Something went wrong while sending email🌋:', error);
    }
  };

  return (
    <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 mt-16">
      <div className="mx-auto max-w-lg text-center">
        <h1 className="text-2xl font-bold sm:text-3xl text-titleText">Contact Us</h1>
        <p className="mt-4 text-gray-500">
          Please fill out the form below, and we will get back to you as soon as possible.
        </p>
      </div>

      <form onSubmit={handleSubmit} className="mx-auto mb-0 mt-8 max-w-md space-y-4">
        {/* Name Field */}
        <div>
          <label htmlFor="name" className="sr-only">Name</label>
          <div className="relative">
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm border"
              placeholder="Enter your name"
            />
            
          </div>
        </div>

        {/* Phone Number Field */}
        <div>
          <label htmlFor="phoneNumber" className="sr-only">Phone Number</label>
          <div className="relative">
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm border"
              placeholder="Enter your phone number"
            />
            
          </div>
        </div>

        {/* Message Field */}
        <div>
          <label htmlFor="message" className="sr-only">Message</label>
          <div className="relative">
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              className="w-full rounded-lg border-gray-200 p-4 text-sm shadow-sm border"
              placeholder="Enter your message"
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="inline-block rounded-lg bg-primaryAccent px-5 py-3 text-sm font-bold text-titleText"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
