import React from "react";
import { Link } from "react-router-dom";
import { productList } from "../utils/constants";

function Product() {
  return (
    <article className="flex flex-wrap gap-8">
      {productList?.map((item, index) => {
        return (
          <div className="w-96" key={index}>
            <div className="aspect-video w-[100%] bg-gray-100 rounded-md">
              <img
                src={item?.ProductImage} // Correct path relative to the public folder
                alt={item?.ProductName}
                className="object-cover w-full h-full rounded-md"
              />
            </div>
            <div className="flex flex-col gap-2">
              <h4 className="scroll-m-20 text-2xl font-bold tracking-tight text-titleText">
                {item?.ProductName}
              </h4>
              <p className="text-black text-xl font-semibold">
                RS {item?.ProductPrice}
              </p>
              <p className="line-clamp-3 text-sm/relaxed text-gray-500">
                {item?.ProductDesc}
              </p>

              <Link
                to={`/products/${item?.ProductName}`}
                className="relative inline-block group text-primaryAccent uppercase"
              >
                Know More
                <span className="absolute left-0 bottom-0 w-0 h-[2px] bg-text transition-all duration-300 ease-in-out group-hover:w-full"></span>
              </Link>
            </div>
          </div>
        );
      })}
    </article>
  );
}

export default Product;
