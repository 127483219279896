import React from "react";
import { aboutUsData } from "../utils/constants";
const About = () => {
  return (
    <>
      <section className="mt-16">
        <div className="">
          <h2 className="text-3xl font-bold sm:text-4xl text-center">
            JBS BIOTECH
          </h2>

          <p className="mt-4 text-gray-500 text-center md:max-w-[60vw] mx-auto">
            is a leading pharmaceutical company dedicated to improving global
            health through innovative research, development, and distribution of
            life-saving medications and healthcare products. Since our founding
            in [2016], we have remained committed to our mission of [Committed
            to healthier tomorrow ]
          </p>

          <div className="mt-8 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {aboutUsData?.map((item, index) => {
              return (
                <div key={index} className="bg-gray-50 rounded-xl  p-8 shadw-md transition shadow-md hover:border-primaryAccent hover:shadow-primaryAccent">
                  {item.icon}
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-10 h-10 text-primaryAccent"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M12 14l9-5-9-5-9 5 9 5z" />
                    <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                    />
                  </svg> */}

                  <h2 className="mt-4 text-xl font-bold">{item.title}</h2>

                  <p className="mt-1 text-sm text-gray-500">
                    {item.data}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* <Header /> */}

      {/* <section className="mb-8">
          <h2 className="text-2xl font-semibold font-PTSerif text-yellow-700 mb-4">Our Values</h2>
          <ul className="list-disc pl-6">
            <li className="text-yellow-800 font-PTSerif text-xl"><strong>Integrity:</strong> We uphold the highest standards of integrity, ethics, and transparency in everything we do.</li>
            <li className="text-yellow-800 font-PTSerif text-xl"><strong>Innovation:</strong> We are committed to innovation and continuous improvement in research, development, and manufacturing processes.</li>
            <li className="text-yellow-800 font-PTSerif text-xl"><strong>Quality:</strong> We prioritize quality and safety in all our products and operations, ensuring compliance with regulatory standards and guidelines.</li>
            <li className="text-yellow-800 font-PTSerif text-xl"><strong>Collaboration:</strong> We foster a culture of collaboration, teamwork, and partnership with healthcare professionals, researchers, and stakeholders.</li>
          </ul>
        </section> */}

      {/* <Footer /> */}
    </>
  );
};

export default About;
