import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { productList } from "../utils/constants";

function SingleProductPage() {
  const [data, setData] = useState([]);
  const { productName } = useParams();
  function filterByProductName(productList, productName) {
    return productList.filter(
      (product) => product?.ProductName === productName
    );
  }
  useEffect(() => {
    setData(filterByProductName(productList, productName));
  }, [productName]);
  return (
    <div className="flex flex-col md:flex-row gap-4 w-full">
      <div className="aspect-video w-[100%] bg-gray-100 rounded-md">
        <img
          src={data[0]?.ProductImage} // Correct path relative to the public folder
          alt={data[0]?.ProductName}
          className="object-cover w-full h-full rounded-md"
        />
      </div>
      <div className="flex flex-col gap-2">
        <h4 className="scroll-m-20 text-2xl font-bold tracking-tight text-titleText">
          {data[0]?.ProductName}
        </h4>
        <p className="text-black text-xl font-semibold">
          RS {data[0]?.ProductPrice}
        </p>
        <p className=" text-sm/relaxed text-gray-500">{data[0]?.ProductDesc}</p>
      </div>
    </div>
  );
}

export default SingleProductPage;
