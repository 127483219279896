import React, { useEffect, useState } from 'react';
import { navData } from '../utils/constants';
import { Link } from 'react-router-dom';
import logo from "../assets/JBS LOGO.png"

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [showNavbar, setShowNavbar] = useState(true);
  const path = window.location.href; // Use router to get the current path

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY && currentScrollY > 50) {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";
  }, [isOpen]);

  return (
    <>
      {/* Navbar */}
      <nav
        className={`fixed top-0 left-0 w-full z-50 bg-whit transition-transform duration-300 ease-in-out bg-primaryAccent ${
          showNavbar ? "translate-y-0" : "-translate-y-24"
        }`}
      >
        <div className="w[80vw] px-4 md:max-w-[80vw] mx-auto rouded-full bg-primayAccent border-titleText borer-2">
          <div className="flex justify-between items-center px-4 py-2">
            <div className="text-2xl font-bold text-titleText">
                <img src={logo} className='w-16' alt='img.jpg
                '></img>
            </div>

            {/* Desktop Menu - Hidden on mobile */}
            <div className="hidden md:flex space-x-8">
              {navData?.map((item, index) => {
                const isActive = path === item.link; // Check if current route matches the link
                return (
                  <Link
                    key={index}
                    to={item.link}
                    className={`inline-block font-bold capitalize ${
                      isActive ? "text-primaryAccent" : "text-titleText"
                    } transition-colors duration-300`}
                  >
                    {item.name}
                  </Link>
                );
              })}
            </div>

            {/* Hamburger Menu - Visible on mobile only */}
            <div
              id="nav-icon3"
              className={`md:hidden ${isOpen ? "open" : ""}`} // Hidden on desktop
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Menu - Visible only when hamburger is clicked */}
      <div
        className={`fixed top-0 left-0 w-full h-full bg-titleText z-40 transform transition-transform duration-300 ease-in-out md:hidden ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <ul className="flex flex-col items-center justify-center h-full text-white space-y-8">
          {navData?.map((item, index) => {
            const isActive = path === item.link; // Check if current route matches the link
            return (
              <li key={index} className="relative text-4xl group">
                <Link
                  to={item.link}
                  onClick={() => setIsOpen(false)}
                  className={`inline-block font-bold capitalize ${
                    isActive ? "text-primaryAccent" : "text-primaryAccent"
                  } transition-colors duration-300`}
                >
                  {item.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default Navbar;
