import { FaHistory } from "react-icons/fa";
import { PiGlobeHemisphereEastFill } from "react-icons/pi";
import { MdCenterFocusStrong } from "react-icons/md";
import {FaCodeCommit} from "react-icons/fa6"

export const aboutUsData=[
    {
        title:"our history",
        icon:<FaHistory className="w-10 h-10 text-primaryAccent"></FaHistory>,
        data:"JBS BIOTECH was established in 2016 by SIMANCHAL MAHAPATRO with a vision to vision statement. Over the years, we have grown into a trusted leader in the pharmaceutical industry, with a strong reputation for excellence in quality, safety, and efficacy."
    },
    {
        title:"Our Mission",
        icon:<PiGlobeHemisphereEastFill className="w-10 h-10 text-primaryAccent"></PiGlobeHemisphereEastFill>,
        data:"At [JBS BIOTECH], our mission is to [mission statement]. We are dedicated to advancing medical science, improving patient outcomes, and enhancing quality of life for people around the world."
    },
    {
        title:"Our Values",
        icon:<MdCenterFocusStrong className="w-10 h-10 text-primaryAccent"></MdCenterFocusStrong>,
        data:"JBS BIOTECH was established in 2016 by SIMANCHAL MAHAPATRO with a vision to vision statement. Over the years, we have grown into a trusted leader in the pharmaceutical industry, with a strong reputation for excellence in quality, safety, and efficacy."
    },
    {
        title:"Areas of Focus",
        icon:<MdCenterFocusStrong className="w-10 h-10 text-primaryAccent"></MdCenterFocusStrong>,
        data:"JBS BIOTECH specializes in specific areas of focus, including list of therapeutic areas or product categories. Our diverse portfolio of products addresses the healthcare needs of patients across geographical locations."
    },
    {
        title:"Our Commitment",
        icon:<FaCodeCommit className="w-10 h-10 text-primaryAccent"></FaCodeCommit>,
        data:"At JBS BIOTECH, we are dedicated to making a meaningful impact on global health by delivering innovative healthcare solutions that improve the lives of patients and contribute to the advancement of medical science."
    },
]

export const navData=[
    {
        name:"home",
        link:"/"
    },
    {
        name:"products",
        link:"/products"
    },
    {
        name:"about",
        link:"/about"
    },
    {
        name:"contact",
        link:"/contact"
    },
]

export const productList=[
    {
      ProductName: "JBCAL PLUS",
      ProductDesc: "Serving Size: One Tablet. Each film coated tablet contains: Calcitriol 0.25 mcg, Calcium Citrate 1000 mg (100% RDA), Zinc Sulphate Monohydrate 7.5 mg (44.11% RDA), Pyridoxine Hydrochloride 3 mg (96.77% RDA), Sodium Borate Pentahydrate 2 mg, Folic Acid 300 mcg (100% RDA), Vitamin K2-7 45 mcg (81.82% RDA), Vitamin D3 15 mcg (100% RDA). Colour: Red Oxide of Iron.",
      ProductImage: "/JBS_Pics/Jbs pics/JBCAL PLUS/jbcal-plus (1) NEW.jpg",
      ProductPrice: "140/-"
    },
    {
      ProductName: "JBNAC PLUS",
      ProductDesc: "Each film coated tablet contains: Aceclofenac IP 100 mg, Paracetamol IP 325 mg, Serratiopeptidase IP 15 mg (as enteric coated granules 30,000 units of Enzymatic Activity). Excipients: q.S.",
      ProductImage: "/JBS_Pics/Jbs pics/JBNAC SP TAB/JBNAC SP.jpg",
      ProductPrice: "95/-"
    },
    {
      ProductName: "HUTCH SYP 200ML",
      ProductDesc: "Each 5 ml contains: Protein 333 mg (As Protein Hydrolysate 20%), Vitamin A 2500 I.U., Vitamin B1 0.84 mg, Vitamin B2 0.84 mg, Vitamin B6 0.50 mg, Vitamin C 20 mg, Vitamin E (Acetate) 40 mg, D-Panthenol 1.66 mg, Niacinamide 8.33 mg, Zinc (As Zinc Sulphate) 2.66 mg, Magnesium Chloride 3.33 mg, Manganese Chloride 0.03 mg. In a Flavoured Syrupy base.",
      ProductImage: "/JBS_Pics/Jbs pics/HUTCH SYP/Hutch 200ml NEW.jpg",
      ProductPrice: "145/-"
    },
    {
      ProductName: "LIMKA SYP",
      ProductDesc: "Each 5 ml contains: Potassium Citrate IP 1100 mg, Magnesium Citrate USP 375 mg, Pyridoxine Hydrochloride IP 20 mg. In a palatable flavoured syrupy base.",
      ProductImage: "/JBS_Pics/Jbs pics/LIMKA SYP/LIMKA Syrup 200ml NEW.jpg",
      ProductPrice: "145/-"
    },
    {
      ProductName: "JBMOX CV 625",
      ProductDesc: "Each Film Coated Tablet Contains: Amoxycillin Trihydrate IP (eq. to Amoxycillin) 500 mg, Potassium Clavulanate Diluted IP (eq. to Clavulanic Acid) 125 mg. Excipients: q.S.",
      ProductImage: "/JBS_Pics/Jbs pics/JBMOX CV 625/JBMOX CV 625 10X1X10 ALU ALU NEW.jpg",
      ProductPrice: "204/-"
    },
    {
      ProductName: "RAJOCID MPS",
      ProductDesc: "Each 5 ml contains: Magaldrate IP (eq. to Anhydrous Magaldrate) 400 mg, Simethicone IP 20 mg. In a Flavoured Sorbitol base.",
      ProductImage: "/JBS_Pics/Jbs pics/RAJOCID MPS SYP/RAJOCID MPS 170 ml (1) 1.jpg",
      ProductPrice: "130/-"
    },
    {
      ProductName: "GYNE GEST - 200 SR",
      ProductDesc: "Each film coated sustained tablet contains: Progesterone (Micronized) IP 200 mg. Excipients: q.s.",
      ProductImage: "/JBS_Pics/Jbs pics/GYNE GEST/GYNE GEST 200 SR 10x1x10 new.jpg",
      ProductPrice: "340/-"
    },
    {
      ProductName: "HPTEL - 40",
      ProductDesc: "Each uncoated tablet contains: Telmisartan IP 40 mg. Excipients: q.S.",
      ProductImage: "/JBS_Pics/Jbs pics/HPTEL 40/HPTEL 40 NEW.jpg",
      ProductPrice: "65/-"
    },
    {
      ProductName: "J-BACT",
      ProductDesc: "Mupirocin IP 2% w/w. In an ointment base.",
      ProductImage: "/JBS_Pics/Jbs pics/J-BACT/J-BACT (1) NEW.jpg",
      ProductPrice: "85/-"
    },
    {
      ProductName: "HUTCH SG CAP",
      ProductDesc: "Each soft gelatin capsule contains: Omega 3 Fatty Acids BP 500 mg, Alpha Lipoic Acid Methylcobalamin USP 50 mg, Alpha Lipoic Acid Methylcobalamin IP 1500 mcg, Chromium Picolinate USP 200 mcg, Sodium Selenite BP 75 mcg. Excipients.",
      ProductImage: "/JBS_Pics/Jbs pics/HUTCH SG CAP/Hutch SG Softgel 1.jpg",
      ProductPrice: ""
    },
    {
      ProductName: "IPROX - SR",
      ProductDesc: "Each film coated tablet contains: Isoxsuprine Hydrochloride (SR) 40 mg (As Sustained Release Form).",
      ProductImage: "",
      ProductPrice: ""
    },
    {
      ProductName: "POLYGRAM - CV Dry Syp",
      ProductDesc: "Each 5 ml of reconstituted suspension contains: Cefpodoxime Proxetil IP 100 mg, Potassium Clavulanate Diluted IP (eq. to Clavulanic Acid) 62.5 mg. Excipients: q.s.",
      ProductImage: "",
      ProductPrice: ""
    },
    {
      ProductName: "POLYGRAM CV Tab.",
      ProductDesc: "Each Film Coated Tablet Contains: Cefpodoxime Proxetil IP (eq. to Cefpodoxime) 200 mg, Potassium Clavulanate Diluted IP (eq. to Clavulanic Acid) 125 mg. Excipients: q.S.",
      ProductImage: "/JBS_Pics/Jbs pics/POLYGRAM CV TAB/Polygram-CV Tab (10x1x10 Strip) (2) new.jpg",
      ProductPrice: ""
    },
    {
      ProductName: "JBTEAR EYE DROP",
      ProductDesc: "Sodium Carboxymethyl-Cellulose IP 1% w/v, Stabilized oxychloro Complex 0.0075% w/v (as preservative). Sterile Aqueous base: q.S.",
      ProductImage: "/JBS_Pics/Jbs pics/JB TEAR EYE DROP/JB-TEAR EYE DROPS new.jpg",
      ProductPrice: ""
    }
  ]
  