import React from "react";
// import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Body from "./components/Body";
import Contact from "./components/Contact";
import About from "./components/About";
import Product from "./components/Product";
import Footer from './components/Footer'

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import SingleProductPage from "./components/SingleProductPage";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Body />} />
        <Route path="/products/:productName" element={<><Navbar /><div className='w-full px-8 md:px-0 md:max-w-[80vw] mx-auto py-12 mt-24'><SingleProductPage /></div><Footer /></>} />
        <Route path="/contact" element={<><Navbar /><Contact /><Footer /></>} />
        <Route path="/about" element={<><Navbar /><div className='w-full px-8 md:px-0 md:max-w-[80vw] mx-auto py-12'><About /></div><Footer /></>} />
        <Route path="/products" element={<><Navbar /><div className='w-full px-8 md:px-0 md:max-w-[80vw] mx-auto py-12 mt-24'><Product></Product></div><Footer /></>} />
      </Routes>
    </Router>
  );
};

export default App;
